<template>
  <div>
    <Sidebar/>
    <div class="content">
      <NavBar />
      <main v-readonly="isReadonly">
        <div class="header">
          <div class="left">
            <h1>{{ this.project_name }}</h1>
            <ul class="breadcrumb">
              <li>Projeto - Elétrico Predial</li>
            </ul>
          </div>
        </div>

        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Informações gerais</h3>
            </div>
            <div class="row">
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome da projeto
                  </label>
                  <input v-model="project_name" type="text" class="form-control" name="project_name"
                    placeholder="Digite o nome da projeto" />
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Unidade Consumidora 
                  </label>
                  <input v-model="consumer_unit" type="text" class="form-control" name="project_name"
                    placeholder="Informe a UC" />
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Será Empresa?
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="is_legal_entity" @change="legalEntity">
                      <option value="0">Não</option>
                      <option value="1">Sim</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-12" :class="{'col-md-6': !is_legal_entity,  'col-md-5' : is_legal_entity}">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome do titular da UC
                  </label>
                  <input v-model="uc_holder_name" type="text" class="form-control" name="uc_holder_name"
                    placeholder="Digite o nome da projeto" />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    RG
                  </label>
                  <input v-model="rg" type="text" class="form-control" name="rg"
                    placeholder="0123456" />
                </div>
              </div>
              <div v-if="is_legal_entity"  class="col-md-4 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome da Empresa
                  </label>
                  <input v-model="company_name" type="text" class="form-control" name="company_name"
                    placeholder="Razão Social" />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    {{ !is_legal_entity ? 'CPF' : 'CNPJ' }}
                  </label>
                  <input v-model="cpf_cnpj" type="text" class="form-control" name="cpf_cnpj"
                    :placeholder="is_cpf ? '000.000.000.-00' : '01.001.001/0001-01'" @input="autoUser"/>
                </div>
              </div>
             
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Telefone
                  </label>
                  <input v-model="phone" type="text" class="form-control" name="phone"
                    placeholder="(00) 0 0000-0000" />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    CEP
                  </label>
                  <input v-model="cep" type="text" class="form-control" name="cep"
                    placeholder="00000-000" @input="search"/>
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Cidade
                  </label>
                  <input v-model="city" type="text" class="form-control" name="city"
                    placeholder="Aguardando..." readonly/>
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Estado
                  </label>
                  <input v-model="state" type="text" class="form-control" name="state"
                    placeholder="Aguardando..." readonly/>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Logradouro
                  </label>
                  <input v-model="street" type="text" class="form-control" name="street"
                    placeholder="Aguardando..." readonly/>
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Bairro
                  </label>
                  <input v-model="neighborhood" type="text" class="form-control" name="neighborhood"
                    placeholder="Aguardando..." readonly/>
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Numero
                  </label>
                  <input v-model="address_number" type="number" class="form-control" name="address_number"
                    placeholder="Ex: 10"/>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Complemento
                  </label>
                  <input v-model="complement" type="text" class="form-control" name="complement"
                    placeholder="Ex: Casa, APT, Sítio, etc"/>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Situação
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="status">
                      <option value="1">Aberto</option>
                      <option value="2">Em progresso</option>
                      <option value="3">Protocolado</option>
                      <option value="4">Em Parecer de Acesso</option>
                      <option value="5">Obra em andamento</option>
                      <option value="6">Aprovado</option>
                      <option value="7">Vistoria solicitada</option>
                      <option value="8">Finalizado</option>
                      <option value="9">Cancelado</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="role == 2" class="row mt-5">
              <div class="col-12">
                <span class="form-label mb-3">
                  <h6 class="pl-10">Financeiro</h6>
                </span>
              </div>
              
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Qual será o valor do projeto?
                  </label>
                  <div class="input-group mb-1">
                    <money v-model="amount_project" class="form-control" v-bind="money"></money>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Qual será o valor do ATR/TRT?
                  </label>
                  <div class="input-group mb-1">
                    <money v-model="amount_art_trt" class="form-control" v-bind="money"></money>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Qual o valor pago?
                  </label>
                  <div class="input-group mb-1">
                    <money v-model="amount_paid" class="form-control" v-bind="money"></money>
                  </div>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-input mt-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Observação
                  </label>
                  <div class="areatext" v-if="!isReadonly">
                    <quill-editor
                    v-model="observation"
                    :options="editorOptions">
                    </quill-editor>
                  </div>
                  <div class="areatext areatext-div" v-if="isReadonly" v-html="observation"></div>
                </div>
              </div>
            </div>
            <div v-if="role == 2" class="w-100 d-flex align-items-center justify-content-end mt-5">
              <button class="btn btn-primary btn-all" @click="update">
                <span>Editar Projeto</span>
              </button>
            </div>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-file"></i>
              <h3>Lista de Anexos</h3>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Criação</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="attachment in attachments" :key="attachment.id">
                  <td>{{ attachment.name }}</td>
                  <td>{{ formatDate(0, attachment.created_at) }}</td>
                  <td class="actions-icon">
                    <a target="_blank" :href="baseStorage+attachment.path">
                      <span data-bs-toggle="tooltip" tooltip-bs-title="Ver detalhes do projeto" class="bx bx-detail ml-5 mr-5 icon-primary">
                      </span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </div>

  </div>
</template>

<script>
import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

import { Money } from 'v-money';

export default {
  components:{
    NavBar,
    Sidebar,
    Money,
  },
  data() {
    return {
      role: this.$store.state.role,
      isReadonly: (this.$store.state.role == 2)? false : true,
      project_name: '',
      consumer_unit: '',
      uc_holder_name: '',
      cpf_cnpj: '',
      rg: '',
      phone: '',
      street: '',
      address_number: '',
      neighborhood: '',
      city: '',
      state: '',
      cep: '',
      company_name: '',
      observation: '',
      has_attachment: '',
      complement: '',
      type: 3,
      is_legal_entity: 0,
      status: 0,
      is_cpf: true,
      token: Cookie.get('_token'),


      attachments: [],
      isDisabled: true,
      
      //Financeiro
      amount_project: 0,
      amount_art_trt: 0,
      amount_paid: 0,

      money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          precision: 2,
          masked: false
      }
    };
  },
  methods: {
    clear() {
      this.project_name= '';
      this.consumer_unit= '';
      this.uc_holder_name= '';
      this.cpf_cnpj= '';
      this.rg= '';
      this.phone= '';
      this.street= '';
      this.address_number= '';
      this.neighborhood= '';
      this.city= '';
      this.state= '';
      this.cep= '';
      this.company_name= '';
      this.observation= '';
      this.has_attachment= '';
      this.complement= '';
      this.type = 0;
      this.is_legal_entity = 0;
      this.status = 0;
      this.is_cpf= true;
    },
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {


        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
    },
    amountConvert(value) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    legalEntity() {
      this.is_cpf = !this.is_cpf;
      this.cpf_cnpj = '';
    },
    update() {

      var json = {
        project_name: this.project_name,
        consumer_unit: this.consumer_unit,
        uc_holder_name: this.uc_holder_name,
        cpf_cnpj: this.cpf_cnpj,
        rg: this.rg,
        phone: this.phone,
        street: this.street,
        address_number: this.address_number,
        neighborhood: this.neighborhood,
        city: this.city,
        state: this.state,
        zip_code: this.cep,
        company_name: this.company_name,
        observation: this.observation,
        has_attachment: this.has_attachment,
        complement: this.complement,
        project_type: this.type,
        is_legal_entity: this.is_legal_entity,
        id_state: this.status,

        //Financeiro
        amount_project: this.amount_project,
        amount_art_trt: this.amount_art_trt,
        amount_paid: this.amount_paid,

      };

      this.$http
        .put("user/projects/" + this.$route.params.projeto, json, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + this.token
          },
        }).then((response) => {
          this.showAlert('success', response.data.message);
          this.load();
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }
        });
    },
    search(){
      if(!this.cep || this.cep.length < 8){
        
        return;
      }

      this.$http.get('/cep/'+this.cep)
      .then( (response) =>{
        console.log(response);
        this.street= response.data.logradouro;
        this.neighborhood= response.data.bairro;
        this.city= response.data.localidade;
        this.state= response.data.uf;
      }).catch((error) => {
        if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
        } else {
          this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
        }
      })
    },
    formatDate(type, date) {
      if (!date) {
        return '';
      }

      const dateFormat = new Date(date);

      if (type == 0) {
        return `${dateFormat.getDate().toString().padStart(2, '0')}/${(dateFormat.getMonth() + 1).toString().padStart(2, '0')}/${dateFormat.getFullYear()}`;
      } else {

        return `${dateFormat.getDate().toString().padStart(2, '0')}/${(dateFormat.getMonth() + 1).toString().padStart(2, '0')}/${dateFormat.getFullYear()} às ${dateFormat.getHours().toString().padStart(2, '0')}:${dateFormat.getMinutes().toString().padStart(2, '0')}`;
      }

    },
    load() {

      this.$http.get("user/projects/" + this.$route.params.projeto, {
        headers: {
          "Authorization": "Bearer " + this.token
        },
      }).then((response) => {

        this.project_name = response.data.project.project_name;
        this.consumer_unit = response.data.project.consumer_unit;
        this.uc_holder_name = response.data.project.uc_holder_name;
        this.cpf_cnpj = response.data.project.cpf_cnpj;
        this.rg = response.data.project.rg;
        this.phone = response.data.project.phone;
        this.street = response.data.project.street;
        this.address_number = response.data.project.address_number;
        this.neighborhood = response.data.project.neighborhood;
        this.city = response.data.project.city;
        this.state = response.data.project.state;
        this.cep = response.data.project.zip_code;
        this.company_name = response.data.project.company_name;
        this.observation = response.data.project.observation;
        this.has_attachment = response.data.project.has_attachment;
        this.complement = response.data.project.complement;
        this.type = response.data.project.project_type;
        this.is_legal_entity = response.data.project.is_legal_entity;

        this.status= response.data.project.id_state;

        this.attachments = response.data.attachments;

        this.amount_project = response.data.amount_project;
        this.amount_art_trt = response.data.amount_art_trt;
        this.amount_paid = response.data.amount_paid;

      })
      .catch((error) => {
        if (error.response && error.response.data) {
          this.showAlert('error', error.response.data);
        } else {
          this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
        }
      });
    },
    autoUser(){
      if(!this.cpf_cnpj || (this.is_legal_entity == 0 && this.cpf_cnpj.length < 11) || (this.is_legal_entity == 1 && this.cpf_cnpj.length < 14)){
        return;
      }

      var json = {
        'number': this.cpf_cnpj,
        'type': this.is_legal_entity
      }

      this.$http.post('/user/search', json,{
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer " + this.token
          },
        })
      .then( (response) =>{

        if(!response.data.status){
          return;
        }


        this.uc_holder_name= response.data.items.full_name;
        this.rg= response.data.items.rg;
        this.phone= response.data.items.phone;
        this.state= response.data.items.state;

        this.integrator= response.data.items.integrators;
        this.is_legal_entity= response.data.items.is_legal_entity;

        if(this.is_legal_entity){
          this.company_name= response.data.extra.company_name;
        }

        this.address_number= response.data.items.address_number;
        this.street= response.data.items.street;
        this.neighborhood= response.data.items.neighborhood;
        this.city= response.data.items.city;
        this.state= response.data.items.state;
        this.cep= response.data.items.zip_code;
        this.complement= response.data.items.complement;

        const inputs = this.$el.querySelectorAll('input[readonly]');

        inputs.forEach(input => {
          input.removeAttribute('readonly');
        });
        
      }).catch((error) => {
        if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
        } else {
          this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
        }
      })
    },

  },
  created(){
    this.load();
  }
};
</script>
