<template>
  <div>
    <Sidebar/>
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            
            <h1>Financeiro</h1>
          </div>
        </div>
      
        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class='bx bx-receipt'></i>
              <h3>Extrato</h3>
            </div>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Projeto</th>
                  <th>Valor de Projeto</th>
                  <th>ART/TRT</th>
                  <th>Total</th>
                  <th>Valor Pago</th>
                  <th>Valor Devedor</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>{{ item.project_name }}</td>
                  <td>{{ convertAmount(item.amount_project) }}</td>
                  <td>{{ convertAmount(item.amount_art_trt) }}</td>
                  <td>{{ convertAmount(item.amount_project + item.amount_art_trt)}}</td>
                  <td>{{ convertAmount(item.amount_paid) }}</td>
                 
                  <td>{{ convertAmount((item.amount_project + item.amount_art_trt) - item.amount_paid) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders f">
            <table>
              <thead>
                <tr>
                  <th>Valor de Projeto Total</th>
                  <th>ART/TRT Total</th>
                  <th>Saldo Total</th>
                  <th>Saldo Pago Total</th>
                  <th>Saldo Devedor Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ convertAmount(amount_project) }}</td>
                  <td>{{ convertAmount(amount_art_trt) }}</td>
                  <td>{{ convertAmount(amount_project + amount_art_trt)}}</td>
                  <td>{{ convertAmount(amount_paid) }}</td>
                  <td>{{ convertAmount((amount_project + amount_art_trt) - amount_paid) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </div>

  </div>
</template>
<script>

import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

export default {
  components:{
    NavBar,
    Sidebar
  },
  data() {
    return {
      items: [],
      author:"",
      token: "",
      amount_project: 0,
      amount_art_trt: 0,
      amount_pending: 0,
      amount_paid: 0,
    };
  },
  methods: {
    load() {
      this.$http.get("user/extract/list", {
          headers: {
            "Authorization": "Bearer " + this.token
          },
        })
        .then((response) => {
          this.items = response.data.extract;

          this.amount_project   = response.data.amount_project;
          this.amount_art_trt   = response.data.amount_art_trt;
          this.amount_pending   = response.data.amount_pending;
          this.amount_paid      = response.data.amount_paid;

        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }

        });
    },
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {


        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        text: textMsg,
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
    },
    formatDate(type, date) {
      if (!date) {
        return '';
      }

      const dateFormat = new Date(date);

      if (type == 0) {
        return `${dateFormat.getDate().toString().padStart(2, '0')}/${(dateFormat.getMonth() + 1).toString().padStart(2, '0')}/${dateFormat.getFullYear()}`;
      } else {

        return `${dateFormat.getDate().toString().padStart(2, '0')}/${(dateFormat.getMonth() + 1).toString().padStart(2, '0')}/${dateFormat.getFullYear()} às ${dateFormat.getHours().toString().padStart(2, '0')}:${dateFormat.getMinutes().toString().padStart(2, '0')}`;
      }

    },
    getStatusClass(status) {
      switch (status) {
        case 1: // completed
          return 'status completed';
        case 0: // cancelled
          return 'status cancelled';
        default:
          return 'status pending';
      }
    },
    getStatusText(status) {
      switch (status) {
        case 1: // completed
          return 'Ativo';
        case 0: // pending
          return 'Inativo';
        default:
          return 'Desconhecido';
      }
    },
    convertAmount(value) {
        return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
  },
  created() {
    if(Cookie.get('_token')){
      this.token = Cookie.get('_token')
    }  

    this.load();
  },
};
</script>