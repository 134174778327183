<template>
  <div>
    <Sidebar />
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Minha conta</h1>
          </div>
        </div>
        <!-- <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Informações</h3>
            </div>
            <div class="row">
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label"> Nome </label>
                  <input v-model="name" type="text" class="form-control" name="username" placeholder="Seu nome completo." />
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label"> E-mail </label>
                  <input v-model="email" type="text" class="form-control" name="email" placeholder="Digite seu e-mail." readonly />
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label"> Whatsapp </label>
                  <input v-model="wpp" type="text" class="form-control" name="whatsapp" placeholder="Digite seu número de whatsapp." />
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">
                    Imagem de perfil
                    <i class="bx bxs-help-circle" tooltip-bs-title="Imagem que será exibida no no site principal."></i>
                  </label>
                  <div class="input-group mb-1">
                    <label for="imgProfile" class="input-group-text cursor-pointer">Escolher</label>
                    <span class="input-group-none d-flex align-items-center borderRadius-Right">Aguardando
                      imagem...</span>
                    <input ref="image" class="form-control" type="file" id="imgProfile" hidden />
                  </div>
                  <div class="form-text align-items-center justify-content-between" id="basic-addon4">
                    Tamanho:
                    <label for="">150x150 px</label>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-input mt-3">
                  <label for="" class="form-label">
                    Sobre mim
                    <i class="bx bxs-help-circle" tooltip-bs-title="Essa descrição será exibida no site."></i>
                  </label>
                  <textarea v-model="description" class="form-control" rows="10" placeholder="Fale um pouco sobre você e o projeto"
                    name="about"></textarea>
                </div>
              </div>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-end mt-5">
              <button @click="update" class="btn btn-primary btn-all">
                <span>Salvar</span>
              </button>
            </div>
          </div>
        </div> -->
        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-cog"></i>
              <h3>Configurações Gerais</h3>
            </div>
            <div class="row">
              <div class="col-md-6 col-12">
                <div class="mb-3">
                  <label for="" class="form-label">Logotipo</label>
                  <input id="logotipo" ref="logotipo" type="file" class="form-control" name="logotipo" />
                </div>
                <div class="w-100 d-flex align-items-center justify-content-end mt-">
                  <button class="btn btn-primary btn-all" @click="chanceLogo">
                    <span>Alterar</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-coin"></i>
              <h3>Taxas</h3>
            </div>
            <div class="row">
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Tarifa</label>
                  <input v-model="tarifa" type="number" class="form-control" name="tarifa" placeholder="0"
                    step="0.01" />
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Valor Minimo do WP</label>
                  <input v-model="minWP" type="number" class="form-control" name="minWP" placeholder="0" step="0.01" />
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Valor Maximo do WP</label>
                  <input v-model="maxWP" type="number" class="form-control" name="maxWP" placeholder="0" step="0.01" />
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Minima Insolação</label>
                  <input v-model="minInsolation" type="number" class="form-control" name="minInsolation" placeholder="0"
                    step="0.01" />
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Maxima Insolação</label>
                  <input v-model="maxInsolation" type="number" class="form-control" name="maxInsolation" placeholder="0"
                    step="0.01" />
                </div>
              </div>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-end mt-5">
              <button class="btn btn-primary btn-all" @click="tax">
                <span>Alterar</span>
              </button>
            </div>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Alterar senha</h3>
            </div>
            <div class="row">
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label"> Nova senha </label>
                  <input v-model="new_password" type="password" class="form-control" name="passnew"
                    placeholder="Digite a nova senha." />
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label"> Repetir nova senha </label>
                  <input v-model="renew_password" type="password" class="form-control" name="repassnew"
                    placeholder="Repita a  nova senha." />
                </div>
              </div>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-end mt-5">
              <button class="btn btn-primary btn-all" @click="chance">
                <span>Alterar</span>
              </button>
            </div>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Procuração</h3>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-input mt-3">
                  <div class="main-container">
                      <div class="editor-container editor-container_classic-editor" ref="editorContainerElement">
                        <div class="editor-container__editor">
                          <div ref="editorElement">
                            <ckeditor v-if="isLayoutReady" v-model="editorData" :editor="editor"
                              :config="config" />
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-end mt-5">
              <button class="btn btn-primary btn-all" @click="chanceDoc">
                <span>Alterar Procuracao</span>
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>

import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoLink,
  Autosave,
  BalloonToolbar,
  BlockQuote,
  Bold,
  Code,
  CodeBlock,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  HorizontalLine,
  Indent,
  IndentBlock,
  Italic,
  Link,
  Paragraph,
  RemoveFormat,
  SelectAll,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextPartLanguage,
  TextTransformation,
  Underline,
  Undo
} from 'ckeditor5';

import translations from 'ckeditor5/translations/pt-br.js';

import 'ckeditor5/ckeditor5.css';

export default {

  components: {
    NavBar,
    Sidebar,
  },
  data() {
    return {
      name: "",
      email: "",
      tarifa: 0,
      minWP: 0,
      maxWP: 0,
      minInsolation: 0,
      maxInsolation: 0,
      wpp: "",
      status: "",
      author: "",
      token: "",
      taxes: [],
      new_password: '',
      renew_password: '',
      editorData: '',
      isLayoutReady: false,
      config: null,
      editor: ClassicEditor
    };
  },
  methods: {
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {
        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        text: textMsg,
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
    },
    load() {
      this.$http.get("user/tax", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.tarifa = response.data.tax.tarifa.toFixed(2);
            this.minWP = response.data.tax.minWP.toFixed(2);
            this.maxWP = response.data.tax.maxWP.toFixed(2);
            this.minInsolation = response.data.tax.minInsolation.toFixed(2);
            this.maxInsolation = response.data.tax.maxInsolation.toFixed(2);
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    procuracao() {
      this.$http.get("user/procuracao", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.editorData = response.data.doc;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    chance() {
      if (this.new_password != this.renew_password) {
        this.showAlert("error", "A nova senha e a confirmação não estão iguais.");
        return;
      }

      var json = {
        old: this.old_password,
        new: this.new_password,
        renew: this.renew_password,
      }

      this.$http.post("user/change", json, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          this.showAlert("success", response.data.message);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    chanceDoc() {
      if (!this.editorData) return this.showAlert("error", "A procuração está em branco!");

      var json = {
        type: 1,
        doc: this.editorData,
      }

      this.$http.post("user/doc", json, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          this.showAlert("success", response.data.message);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    tax() {
      var json = {
        tarifa: this.tarifa,
        minWP: this.minWP,
        maxWP: this.maxWP,
        minInsolation: this.minInsolation,
        maxInsolation: this.maxInsolation
      }

      this.$http.post("user/tax", json, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          this.showAlert("success", response.data.message);
          this.load();
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    chanceLogo() {
      console.log("logo")
      var json = {
        type: 1,
        documentUpload: this.$refs.logotipo.files[0],
      }

      this.$http.post("user/upload", json, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          this.showAlert("success", response.data.message);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    chanceProcuration() {

      var json = {
        type: 2,
        documentUpload: this.$refs.procuracao.files[0],
      }

      this.$http.post("user/upload", json, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          this.showAlert("success", response.data.message);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
  },
  created() {
    if (Cookie.get('_token')) {

      this.token = Cookie.get('_token')
    }
    this.load();
    this.procuracao();
  },
  mounted() {

    this.config = {
      toolbar: {
        items: [
          'undo',
          'redo',
          '|',
          'findAndReplace',
          'textPartLanguage',
          '|',
          'heading',
          '|',
          'fontSize',
          'fontFamily',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'subscript',
          'superscript',
          'code',
          'removeFormat',
          '|',
          'specialCharacters',
          'horizontalLine',
          'link',
          'insertTable',
          'highlight',
          'blockQuote',
          'codeBlock',
          '|',
          'alignment',
          '|',
          'outdent',
          'indent'
        ],
        shouldNotGroupWhenFull: true
      },
      plugins: [
        AccessibilityHelp,
        Alignment,
        Autoformat,
        AutoLink,
        Autosave,
        BalloonToolbar,
        BlockQuote,
        Bold,
        Code,
        CodeBlock,
        Essentials,
        FindAndReplace,
        FontBackgroundColor,
        FontColor,
        FontFamily,
        FontSize,
        Heading,
        Highlight,
        HorizontalLine,
        Indent,
        IndentBlock,
        Italic,
        Link,
        Paragraph,
        RemoveFormat,
        SelectAll,
        SpecialCharacters,
        SpecialCharactersArrows,
        SpecialCharactersCurrency,
        SpecialCharactersEssentials,
        SpecialCharactersLatin,
        SpecialCharactersMathematical,
        SpecialCharactersText,
        Strikethrough,
        Subscript,
        Superscript,
        Table,
        TableCaption,
        TableCellProperties,
        TableColumnResize,
        TableProperties,
        TableToolbar,
        TextPartLanguage,
        TextTransformation,
        Underline,
        Undo
      ],
      balloonToolbar: ['bold', 'italic', '|', 'link'],
      fontFamily: {
        supportAllValues: true
      },
      fontSize: {
        options: [10, 12, 14, 'default', 18, 20, 22],
        supportAllValues: true
      },
      heading: {
        options: [
          {
            model: 'paragraph',
            title: 'Paragraph',
            class: 'ck-heading_paragraph'
          },
          {
            model: 'heading1',
            view: 'h1',
            title: 'Heading 1',
            class: 'ck-heading_heading1'
          },
          {
            model: 'heading2',
            view: 'h2',
            title: 'Heading 2',
            class: 'ck-heading_heading2'
          },
          {
            model: 'heading3',
            view: 'h3',
            title: 'Heading 3',
            class: 'ck-heading_heading3'
          },
          {
            model: 'heading4',
            view: 'h4',
            title: 'Heading 4',
            class: 'ck-heading_heading4'
          },
          {
            model: 'heading5',
            view: 'h5',
            title: 'Heading 5',
            class: 'ck-heading_heading5'
          },
          {
            model: 'heading6',
            view: 'h6',
            title: 'Heading 6',
            class: 'ck-heading_heading6'
          }
        ]
      },
      language: 'pt-br',
      link: {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://',
        decorators: {
          toggleDownloadable: {
            mode: 'manual',
            label: 'Downloadable',
            attributes: {
              download: 'file'
            }
          }
        }
      },
      table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
      },
      translations: [translations]
    };

    this.isLayoutReady = true;

  }
};
</script>
