<template>
  <div>
    <Sidebar/>
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Usuário - {{ name }}</h1>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Informações</h3>
            </div>
            <div class="row">
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label"> Nome </label>
                  <input v-model="name" type="text" class="form-control" name="username" placeholder="Seu nome completo." />
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label"> E-mail </label>
                  <input v-model="email" type="text" class="form-control" name="email" placeholder="Digite seu e-mail." disabled />
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label"> Senha </label>
                  <input v-model="password" type="text" class="form-control" name="whatsapp" placeholder="********" />
                  <div class="form-text align-items-center justify-content-between" id="basic-addon4">
                    Deixe em branco caso não queira alterar
                  </div>
                </div>
                
              </div>

              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label"> Whatsapp </label>
                  <input v-model="wpp" type="text" class="form-control" name="whatsapp" placeholder="Digite seu número de whatsapp." />
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">
                    Imagem de perfil
                    <i class="bx bxs-help-circle" tooltip-bs-title="Imagem que será exibida no no site principal."></i>
                  </label>
                  <div class="input-group mb-1">
                    <label for="imgProfile" class="input-group-text cursor-pointer">Escolher</label>
                    <span class="input-group-none d-flex align-items-center borderRadius-Right">Aguardando
                      imagem...</span>
                    <input ref="image" class="form-control" type="file" id="imgProfile" hidden />
                  </div>
                  <div class="form-text align-items-center justify-content-between" id="basic-addon4">
                    Tamanho:
                    <label for="">150x150 px</label>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Situação
                    <i class="bx bxs-help-circle" tooltip-bs-title="Situação da rifa"></i>
                  </label>
                  <select class="form-control" v-model="status">
                    <option value="0">Inativo</option>
                    <option value="1">Ativo</option>
                  </select>
                </div>
              </div>
              <div class="col-12">
                <div class="form-input mt-3">
                  <label for="" class="form-label">
                    Sobre mim
                    <i class="bx bxs-help-circle" tooltip-bs-title="Essa descrição será exibida no site."></i>
                  </label>
                  <textarea v-model="description" class="form-control" rows="10" placeholder="Fale um pouco sobre você e o projeto"
                    name="about"></textarea>
                </div>
              </div>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-end mt-5">
              <button @click="update" class="btn btn-primary btn-all">
                <span>Salvar</span>
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>

import Cookie from "js-cookie";
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

export default {
  components: {
    NavBar,
    Sidebar,
  },
  data() {
    return {
      id: "",
      name: "",
      email: "",
      password: "",
      wpp: "",
      status: "",
      description: "",
      author: "",
      token: "",

    };
  },
  methods: {
    clear() {
      this.id = "";
      this.name = "";
      this.email = "";
      this.password = "";
      this.wpp = "";
      this.status = "";
      this.description = "";
    },
    load() {
      this.$http
        .get("v1/users/" + this.$route.params.user, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.id = response.data.item.id;
          this.name = response.data.item.name;
          this.email = response.data.item.email;
          this.wpp = response.data.item.phone;
          this.status = response.data.item.status;
          this.description = response.data.item.about;
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {
        if (typeof msg.errors === "object" && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(", ")}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        icon: type,
        confirmButtonText: "Confirmar",
        html: textMsg,
      });
    },
    update() {
      var json = {
        name: this.name,
        email: this.email,
        password: this.password,
        phone: this.wpp,
        image:  this.$refs.image.files[0],
        status: this.status,
        about: this.description,
        _method: "PUT"
      };

      this.$http
        .post("v1/users/" + this.id, json, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.showAlert("success", response.data.message);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    showImagem(item) {
        this.$refs['modal-img-new'].show()
        this.viewImage = item;
    },
  },
  mounted() {
    if(Cookie.get('_token')){

      this.token = Cookie.get('_token')
    }  
    this.load();
  },
};
</script>
