<template>
  <div class="nav-top">
    <nav>
      <div class="w-100 d-flex align-items-center justify-content-end gap-5">
        <button  v-if="role == 2" class="btn btn-primary btn-all mr-3" @click="doCopy">
          <i class='bx bx-link'></i> Meu link
        </button>
        <input v-model="darkMode" type="checkbox" id="theme-toggle" hidden />
        <label for="theme-toggle" class="theme-toggle"></label>
      </div>
    </nav>
  </div>

</template>

<script>
import Cookie from 'js-cookie'
export default {
  name: 'NavBar',
  data() {
    return {
      role: 0,
      uuid: Cookie.get('link'),
      darkMode: (Cookie.get('theme') == 'true') ? true : false,
    };
  },
  methods: {
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {
        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        text: textMsg,
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
    },
    doCopy() {
      this.$copyText(this.$base + 'registro/' + this.uuid).then(() => {
        this.showAlert('success', 'Seu link de cadastro foi copiado!')
      });
    }
  },
  created(){
    this.role = this.$store.state.role;
  },
  watch: {
    darkMode() {

      document.body.classList.toggle("dark");
      let theme = Cookie.get('theme');

      if (theme == 'true') {
        Cookie.set('theme', false);
      } else {
        Cookie.set('theme', true);
      }

    },
  },
};
</script>