<template>
  <div>
    <Sidebar/>
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Projetos</h1>
              <ul class="breadcrumb">
                <li>Escolha o tipo de projeto</li>
              </ul>
          </div>
        </div>
        <ul class="insights">
          <li class="box-2" @click="go('solar')">
              <i class="bx bx-sun"></i>
              <span class="info">
                <h5>Energia Solar</h5>
              </span>
            </li>
            <li class="box-1" @click="go('medicao-agrupada')">
              <i class="bx bx-carousel"></i>
              <span class="info">
                <h5>Medição Agrupada</h5>
              </span>
            </li>
            <li class="box-4"  @click="go('eletrico-predial')">
                <i class='bx bx-buildings' ></i>
              <span class="info">
                <h5>Eletrico Predial</h5>
              </span>
            </li>
            <li class="box-5"  @click="go('subestacao-eletrica')">
                <i class='bx bx-border-bottom'></i>
              <span class="info">
                <h5>Subestação Elétrica</h5>
              </span>
            </li>
           
         
        </ul>
      </main>
    </div>
  </div>
</template>
<script>

import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

export default {
  components:{
    NavBar,
    Sidebar
  },
  data() {
    return {
      subscribers: 0,
      plans: 0,
      news: 0,
      projects: 0,
      amount: 0,
      users: 0,
      role: 0,
      token: Cookie.get('_token')
    };
  },
  methods: {
    showAlert(type, msg) {
      var textMsg = "";
      if (msg.message) {
        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        text: textMsg,
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
      
    },
    amountConvert(value) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    load() {
      this.$http
        .get("/user/home", {
          headers: {
            "Authorization": "Bearer " + this.token
          },
        })
        .then((response) => {

          if(response.data.items.subscribers){
            this.subscribers = response.data.items.subscribers;
          }
          
          if(response.data.items.plans){
            this.plans = response.data.items.plans;
          }

          if(response.data.items.news){
            this.news = response.data.items.news;
          }

          if(response.data.items.amount){
            this.amount = response.data.items.amount;
          }

          if(response.data.items.users){
            this.users = response.data.items.users;
          }

          this.projects = response.data.items.projects;

        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }

        });
    },
    go(router){
      this.$router.push('/projetos/'+router);
    },
    dev(){
      this.showAlert('warning', 'Pagina em desenvolvimento');
    }
  },
  created() {
    this.role = this.$store.state.role;
  },
};
</script>