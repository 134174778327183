import { createRouter, createWebHistory } from 'vue-router'

import Guard from '@/services/middleware'

import Login from '@/views/LoginView.vue'
import HomeView from '@/views/HomeView.vue'

import SettingsView from '@/views/SettingsView.vue'
import IntegratorsListView from '@/views/Integrators/IntegratorsListView.vue'
import IntegratorsAddView from '@/views/Integrators/IntegratorsAddView.vue'
import IntegratorsManagerView from '@/views/Integrators/IntegratorsManagerView.vue'

import FinancialListView from '@/views/Financial/FinancialListView.vue'
import LeadsListView from '@/views/Leads/LeadsListView.vue'
import CalculatorView from '@/views/Leads/CalculatorEngView.vue'
import CalculatorIntegrationView from '@/views/Leads/CalculatorIntegrationView.vue'


//Clientes
import ClientsListView from '@/views/Clients/ClientsListView.vue'
import ClientsAddView from '@/views/Clients/ClientsAddView.vue'
import ClientsManagerView from '@/views/Clients/ClientsManagerView.vue'

/*
* Projetos 
*/

import ProjectsView from '@/views/Project/ProjectsView.vue'

//Solar
import PhotoVoltaicList from '@/views/Project/PhotoVoltaic/ProjectsListView.vue'
import PhotoVoltaicAdd from '@/views/Project/PhotoVoltaic/ProjectAddView.vue'
import PhotoVoltaicManager from '@/views/Project/PhotoVoltaic/ProjectManagerView.vue'

//Medição Agrupada
import GroupedMeasurementList from '@/views/Project/GroupedMeasurement/ProjectsListView.vue'
import GroupedMeasurementAdd from '@/views/Project/GroupedMeasurement/ProjectAddView.vue'
import GroupedMeasurementManager from '@/views/Project/GroupedMeasurement/ProjectManagerView.vue'

//Elétrico Predial
import BuildingElectricList from '@/views/Project/BuildingElectric/ProjectsListView.vue'
import BuildingElectricAdd from '@/views/Project/BuildingElectric/ProjectAddView.vue'
import BuildingElectricManager from '@/views/Project/BuildingElectric/ProjectManagerView.vue'

//Subestação Elétrica
import ElectrialSubstationList from '@/views/Project/ElectrialSubstation/ProjectsListView.vue'
import ElectrialSubstationAdd from '@/views/Project/ElectrialSubstation/ProjectAddView.vue'
import ElectrialSubstationManager from '@/views/Project/ElectrialSubstation/ProjectManagerView.vue'
import MyDoc from '@/views/MyDoc.vue'
import RegisterView from '@/views/RegisterView.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: Guard.login,
  },
  {
    path: '/registro',
    name: 'register',
    component: RegisterView,
    beforeEnter: Guard.login,
  },
  {
    path: '/registro/:uuid',
    name: 'register',
    component: RegisterView,
    beforeEnter: Guard.login,
  },
  {
    path: '/calculator/:uuid',
    name: 'calculator',
    component: CalculatorView,
  },
  {
    path: '/calculator/:uuid/integration',
    name: 'calculator_integration',
    component: CalculatorIntegrationView,
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/projeto',
    name: 'projeto',
    component: ProjectsView,
    beforeEnter: Guard.auth,
  },

  //Solar
  {
    path: '/projetos/solar',
    name: 'projetos',
    component: PhotoVoltaicList,
    beforeEnter: Guard.auth,
  },
  {
    path: '/projetos/solar/adicionar',
    name: 'projeto-add',
    component: PhotoVoltaicAdd,
    beforeEnter: Guard.auth,
  },
  {
    path: '/projetos/solar/gerenciar/:projeto',
    name: 'project-manager',
    component: PhotoVoltaicManager,
    beforeEnter: Guard.auth,
  },

  //Medição Agrupada
  {
    path: '/projetos/medicao-agrupada',
    name: 'projetos-medicao-agrupada',
    component: GroupedMeasurementList,
    beforeEnter: Guard.auth,
  },
  {
    path: '/projetos/medicao-agrupada/adicionar',
    name: 'projeto-medicao-agrupada-add',
    component: GroupedMeasurementAdd,
    beforeEnter: Guard.auth,
  },
  {
    path: '/projetos/medicao-agrupada/gerenciar/:projeto',
    name: 'project-medicao-agrupada-manager',
    component: GroupedMeasurementManager,
    beforeEnter: Guard.auth,
  },
  //Elétrico Predial
  {
    path: '/projetos/eletrico-predial',
    name: 'projetos-eletrico-predial',
    component: BuildingElectricList,
    beforeEnter: Guard.auth,
  },
  {
    path: '/projetos/eletrico-predial/adicionar',
    name: 'projeto-eletrico-predial-add',
    component: BuildingElectricAdd,
    beforeEnter: Guard.auth,
  },
  {
    path: '/projetos/eletrico-predial/gerenciar/:projeto',
    name: 'project-eletrico-predial-manager',
    component: BuildingElectricManager,
    beforeEnter: Guard.auth,
  },
  //Subestação
  {
    path: '/projetos/subestacao-eletrica',
    name: 'projetos-subestacao-eletrica',
    component: ElectrialSubstationList,
    beforeEnter: Guard.auth,
  },
  {
    path: '/projetos/subestacao-eletrica/adicionar',
    name: 'projeto-subestacao-eletrica-add',
    component: ElectrialSubstationAdd,
    beforeEnter: Guard.auth,
  },
  {
    path: '/projetos/subestacao-eletrica/gerenciar/:projeto',
    name: 'project-subestacao-eletrica-manager',
    component: ElectrialSubstationManager,
    beforeEnter: Guard.auth,
  },
  // ------------
  {
    path: '/config',
    name: 'settings',
    component: SettingsView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/integradores',
    name: 'Integrators',
    component: IntegratorsListView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/integradores/adicionar',
    name: 'user-add',
    component: IntegratorsAddView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/integradores/gerenciar/:user',
    name: 'user-manager',
    component: IntegratorsManagerView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/clientes',
    name: 'clientes',
    component: ClientsListView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/clientes/adicionar',
    name: 'clientes-add',
    component: ClientsAddView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/clientes/gerenciar/:user',
    name: 'clients-manager',
    component: ClientsManagerView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/financeiro',
    name: 'financial',
    component: FinancialListView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/procuracao',
    name: 'procuracao',
    component: MyDoc,
    beforeEnter: Guard.auth,
  },
  {
    path: '/leads',
    name: 'leads',
    component: LeadsListView,
    beforeEnter: Guard.auth,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
