import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    role: null,
  },
  getters: {
    isEngineer(state) {
      console.log(state.role);
      return state.role === 1;
    },
    isIntegrator(state) {
      console.log(state.role);
      return state.role === 2;
    },
  },
  mutations: {
    updateRole(state, newRole) {
      state.role = newRole;
    },
  },
  actions: {
    setRoleOnLogin({ commit }, role) {
      commit('updateRole', role);
    },
  },
  modules: {
  },
  plugins: [createPersistedState()],
})
