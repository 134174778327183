// diretivas/readonly.js
export default {
  bind(el, binding, vnode) {
    const updateState = (value) => {
      const inputs = el.querySelectorAll('input, textarea, select');
      inputs.forEach(input => {
        if (input.tagName.toLowerCase() === 'select') {
          input.disabled = value;
        } else {
          input.readOnly = value;
        }
      });

      // Tratamento especial para quill-editor
      if (vnode.context.$refs.quillEditor && vnode.context.$refs.quillEditor.quill) {
        vnode.context.$refs.quillEditor.quill.enable(!value);
      }
    };

    vnode.context.$nextTick(() => {
      updateState(binding.value);
    });

    el.__vueState__ = updateState;
  },
  update(el, binding) {
    el.__vueState__(binding.value);
  },
  unbind(el) {
    delete el.__vueState__;
  }
};
