import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App);

import axios from 'axios'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const options = {
    confirmButtonColor: '#efce03',
    cancelButtonColor: '#1d1d29',
};

app.use(VueSweetalert2, options);

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import ReadonlyDirective from './diretivas/readonly';

app.directive('readonly', ReadonlyDirective);

import VueClipboard from 'vue-clipboard2';
app.use(VueClipboard);

app.use(store);
app.use(router);

app.component('vue-phone-number-input', VuePhoneNumberInput);

// app.config.globalProperties.baseStorage = 'http://127.0.0.1:8000/storage/';
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/v1';
// app.config.globalProperties.$base = 'http://127.0.0.1:8000/';

app.config.globalProperties.baseStorage = 'https://app.inoveng.com/storage/';
axios.defaults.baseURL = 'https://app.inoveng.com/api/v1';
app.config.globalProperties.$base = 'https://inoveng.com/';

app.config.globalProperties.$api = 'https://app.inoveng.com/api/v1/';

axios.defaults.headers.common['Accept'] = 'application/json';
app.config.globalProperties.$http = axios;


import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';
app.use(CkeditorPlugin);

app.mount('#app');
