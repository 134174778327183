<template>
  <div>
    <Sidebar/>
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Novo Integrador</h1>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Informações</h3>
            </div>
            <div class="row">
              <div class="col-md-5 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome da Integrador
                  </label>
                  <input v-model="full_name" type="text" class="form-control" name="full_name"
                    placeholder="Nome do integrador" />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    CPF
                  </label>
                  <input v-model="cpf" type="text" class="form-control" name="cpf"
                    placeholder="000.000.000.-00" />
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    RG
                  </label>
                  <input v-model="rg" type="text" class="form-control" name="rg"
                    placeholder="0123456" />
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Será Empresa?
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="is_legal_entity" @change="legalEntity">
                      <option value="0">Não</option>
                      <option value="1">Sim</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    E-mail
                  </label>
                  <input v-model="email" type="email" class="form-control" name="email"
                    placeholder="teste@teste.com" />
                </div>
              </div>
              <div v-if="!is_cpf"  class="col-md-5 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome da Empresa
                  </label>
                  <input v-model="company_name" type="text" class="form-control" name="rg"
                    placeholder="Razão Social" />
                </div>
              </div>
              <div v-if="!is_cpf" class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    CNPJ
                  </label>
                  <input v-model="cnpj" type="text" class="form-control" name="cpf"
                    placeholder="01.001.001/0001-01" />
                </div>
              </div>
             
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Telefone
                  </label>
                  <input v-model="phone" type="text" class="form-control" name="phone"
                    placeholder="(00) 0 0000-0000" />
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    CEP
                  </label>
                  <input v-model="cep" type="text" class="form-control" name="cep"
                    placeholder="00000-000" @input="search"/>
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Cidade
                  </label>
                  <input v-model="city" type="text" class="form-control" name="city"
                    placeholder="Aguardando..." readonly/>
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Estado
                  </label>
                  <input v-model="state" type="text" class="form-control" name="state"
                    placeholder="Aguardando..." readonly/>
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Numero
                  </label>
                  <input v-model="address_number" type="number" class="form-control" name="address_number"
                    placeholder="Ex: 10"/>
                </div>
              </div>
              <div class="col-md-5 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Logradouro
                  </label>
                  <input v-model="street" type="text" class="form-control" name="street"
                    placeholder="Aguardando..." readonly/>
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Bairro
                  </label>
                  <input v-model="neighborhood" type="text" class="form-control" name="neighborhood"
                    placeholder="Aguardando..." readonly/>
                </div>
              </div>
              
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Complemento
                  </label>
                  <input v-model="complement" type="text" class="form-control" name="complement"
                    placeholder="Ex: Casa, APT, Sítio, etc"/>
                </div>
                
              </div>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-end mt-5">
              <button class="btn btn-primary btn-all" @click="create">
                <span>Criar Conta</span>
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>

  </div>
</template>

<script>
import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

export default {
  components:{
    NavBar,
    Sidebar,
  },
  data() {
    return {
      full_name: '',
      cpf: '',
      rg: '',
      is_legal_entity: 0,
      email: '',
      phone: '',
      cep: '',
      address_number: '',
      street: '',
      neighborhood: '',
      city: '',
      state: '',
      company_name: '',
      complement: '',
      is_cpf: true,
      token: Cookie.get('_token'),
    };
  },
  methods: {
    clear() {
      this.full_name= '';
      this.email= '';
      this.cpf= '';
      this.rg= '';
      this.phone= '';
      this.street= '';
      this.address_number= '';
      this.neighborhood= '';
      this.city= '';
      this.state= '';
      this.cep= '';
      this.company_name= '';
      this.complement= '';
      this.cnpj = '',
      this.is_legal_entity = 0;
      this.is_cpf= true;
    },
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {


        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
    },
    amountConvert(value) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    legalEntity() {
      this.is_cpf = !this.is_cpf;
    },
    create() {

      var json = {
        full_name: this.full_name,
        cpf: this.cpf,
        rg: this.rg,
        email: this.email,
        phone: this.phone,
        street: this.street,
        address_number: this.address_number,
        neighborhood: this.neighborhood,
        city: this.city,
        state: this.state,
        cnpj: this.cnpj,
        cep: this.cep,
        company_name: this.company_name,
        complement: this.complement,
        is_legal_entity: this.is_legal_entity,
      };

      this.$http
        .post("user/integrators", json, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + this.token
          },
        })
        .then((response) => {
          //this.clear();
          this.showAlert('success', response.data.message);
          this.$router.push('/integradores');
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }

        });
    },
    search(){
      if(!this.cep || this.cep.length < 8){
        
        return;
      }

      this.$http.get('/cep/'+this.cep)
      .then( (response) =>{
        console.log(response);
        this.street= response.data.logradouro;
        this.neighborhood= response.data.bairro;
        this.city= response.data.localidade;
        this.state= response.data.uf;

        const inputs = this.$el.querySelectorAll('input[readonly]');

        inputs.forEach(input => {
          input.removeAttribute('readonly');
        });

      }).catch((error) => {
        if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
        } else {
          this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
        }
      })
    },
  },
  created(){
  }
};
</script>
